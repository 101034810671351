import PropTypes from 'prop-types'
import React from 'react'
import './card.css'
import { VectorImg } from '../img/VectorImg'

export const Card = ({
  status,
  spanClassName,
  text = '[aria-*] ',
  title,
  description,
  url = 'url.svg'
}) => {
  // Function to extract URL from the description
  const extractUrl = (description) => {
    const regex = /\[.*?\]\((.*?)\)/ // Matches markdown link syntax
    const matches = description.match(regex)
    return matches ? matches[1] : null
  }

  const extractedUrl = extractUrl(description)

  return (
    <div className="card">
      <div className={`container ${status}`}>
        <div className="data">
          <div className="text">
            <p className="aria-attributes">
              <span className={`text-wrapper ${spanClassName}`}></span>
              <span className="span">{title}</span>
            </p>
            <p className="div">
              {description}
            </p>
          </div>
          {/* Wrap VectorImg in a div with an onClick event if a URL is found */}
          {extractedUrl
            ? (
            <a href={extractedUrl} target="_blank" rel="noopener noreferrer">
              <VectorImg className="url" alt="Url" src={status === 'ERROR' ? 'image.svg' : url} />
            </a>
              )
            : (
            <VectorImg className="url" alt="Url" src={status === 'ERROR' ? 'image.svg' : url} />
              )}
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  status: PropTypes.oneOf(['PASSED', 'ERROR']),
  spanClassName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string
}

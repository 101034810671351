import React from 'react'
import PropTypes from 'prop-types'

function Container ({ children }) {
  return (
    <div className="container mx-auto max-h-fit">
      <div className="space-y-4 bg-white rounded-t-xl rounded-b-lg border border-gray-300">
        {children}
      </div>
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired
}

export default Container

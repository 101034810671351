import React from 'react'
import PropTypes from 'prop-types'
import { GreenCircleImg } from '../img/GreenCircleImg'
import { LinesBoxImg } from '../img/LinesBoxImg'
import { HourglassImg } from '../img/HourglassImg'
import { EllipseScore } from '../img/EllipseScore'
import styles from './overviewUrl.module.css'

export const OverviewUrl = ({ score, baseUrl, path, errorNum, passedNum, manualNum, notApplNum }) => {
  const scoreColor = score >= 90 ? '#a1eab1' : '#F2C628' // Light green if score > 90, otherwise yellow

  return (
    <div className={styles['overview-url']}>
      <div className={styles['container-header']}>
        <div className={styles.div}>
          <div className={styles['text-wrapper']}>Base URL</div>
          <div className={styles['text-wrapper-2']}>{baseUrl}</div>
        </div>
        <div className={styles.assets}>
          <GreenCircleImg className={styles.element} />
          <HourglassImg className={styles.img} alt="Element" src="4.svg" />
          <LinesBoxImg className={styles['instance-119']} />
        </div>
      </div>
      <div className={styles['container-score']}>
        <div className={styles.score}>
          <div className={styles.chart}>
            <div className={styles.overlap}>
              <EllipseScore className={styles.ellipse} score={score} color={scoreColor} />
              <div className={styles['text-wrapper-3']}>{score}</div> {/* Keeping original styles */}
            </div>
          </div>
          <div className={styles['accessibility-score']}>
            Accessibility
            <br />
            Score
          </div>
        </div>
        <div className={styles.div}>
          <div className={styles['text-wrapper']}>Path</div>
          <div className={styles['text-wrapper-2']}>{path}</div>
        </div>
      </div>
      <div className={styles['container-overview']}>
        <div className={styles.changes}>
          <div className={styles['text-wrapper-4']}>{errorNum}</div>
          <div className={styles['text-wrapper-5']}>NEED CHANGES</div>
        </div>
        <div className={styles.passed}>
          <div className={styles['text-wrapper-6']}>{passedNum}</div>
          <div className={styles['text-wrapper-7']}>PASSED AUDITS</div>
        </div>
        <div className={styles['not-applicable']}>
          <div className={styles['text-wrapper-8']}>{notApplNum}</div>
          <div className={styles['text-wrapper-9']}>NOT APPLICABLE</div>
        </div>
        <div className={styles['need-manual']}>
          <div className={styles['text-wrapper-10']}>{manualNum}</div>
          <div className={styles['NEED-MANUAL-TEST-IN']}>
            NEED MANUAL TEST
            <br />
            IN DEVELOP
          </div>
        </div>
      </div>
    </div>
  )
}

OverviewUrl.propTypes = {
  score: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  errorNum: PropTypes.number.isRequired,
  passedNum: PropTypes.number.isRequired,
  manualNum: PropTypes.number.isRequired,
  notApplNum: PropTypes.number.isRequired
}

export default OverviewUrl

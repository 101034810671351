import React from 'react'

export const CircleLineImg = ({ width, height }) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 291 291" fill="none">
  <path d="M145.287 145.156L288.507 1.9364M288.507 145.156C288.507 224.254 224.385 288.376 145.287 288.376C66.1891 288.376 2.06738 224.254 2.06738 145.156C2.06738 66.0581 66.1891 1.9364 145.287 1.9364C224.385 1.9364 288.507 66.0581 288.507 145.156Z" stroke="black" strokeWidth="3.51523" strokeLinejoin="round"/>
</svg>

  )
}

import { useEffect, useState } from 'react'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import { db } from '../firebase'

export function useProjects (sortField, sortOrder) {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    const fetchProjects = async () => {
      const q = query(
        collection(db, 'accessibility-projects'),
        orderBy('config.timestamp', 'desc')
      )
      const querySnapshot = await getDocs(q)
      const projectsData = querySnapshot.docs.map((doc) => {
        const data = doc.data()
        const config = data.config || {}
        const timestamp = config.timestamp || { toDate: () => new Date(0) }
        return {
          id: doc.id,
          name: config.name || 'Unnamed',
          timestamp,
          webpages: config.webpages || [],
          url: config.url || '',
          scope: config.scope || '',
          environment: config.environment
        }
      })

      // Sorting logic
      projectsData.sort((a, b) => {
        if (sortField === 'name') {
          return sortOrder === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        } else if (sortField === 'urls') {
          return sortOrder === 'asc'
            ? a.webpages.length - b.webpages.length
            : b.webpages.length - a.webpages.length
        }
        return 0
      })

      setProjects(projectsData)
    }

    fetchProjects()
  }, [sortField, sortOrder])

  return projects
}

import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

export const PrintComponent = ({ children }) => {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Exported-PDF',
    onAfterPrint: () => console.log('Print job completed!')
  })

  return (
    <>
      <div ref={componentRef}>
        {/* Render children which are your components to be printed */}
        {children}
      </div>
      <button onClick={handlePrint}>Print / Save as PDF</button>
    </>
  )
}

export default PrintComponent

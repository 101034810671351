import React from 'react'
import styles from './manualTest.module.scss'
import { VectorImg } from '../img/VectorImg'

export const ManualTestLabel = ({ text, description }) => {
  // Function to extract URL from the description
  const extractUrl = (description) => {
    const regex = /\[.*?\]\((.*?)\)/ // This regex matches markdown link syntax
    const matches = description.match(regex)
    return matches ? matches[1] : null // Return the URL if found
  }

  const url = extractUrl(description)

  return (
    <div className={styles.frame}>
      <div className={styles.div} >
        <p className={styles.ariaAttributes}>{text}</p>
        {/* Use an anchor tag to wrap the VectorImg if a URL is found */}
        {url
          ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <VectorImg className={styles.img} />
          </a>
            )
          : (
          <VectorImg className={styles.img} />
            )}
      </div>
    </div>
  )
}

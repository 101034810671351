import React from 'react'

export const AsteriskSquareImg = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
  <path d="M1.65063 1.5H63.6506V63.5H1.65063V1.5Z" fill="white"/>
  <path d="M49.5536 22.5742L32.6506 32.5M32.6506 32.5L49.5536 42.4257M32.6506 32.5L15.7477 22.5742M32.6506 32.5L15.7477 42.4257M32.6506 32.5V12.6485M32.6506 32.5V52.3515M1.65063 1.5H63.6506V63.5H1.65063V1.5Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
</svg>

  )
}

import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { doc, getDoc } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL, getMetadata } from 'firebase/storage'
import { db } from '../firebase'
import ReactToPrint from 'react-to-print'
import { IntroComponent } from '../components/IntroComponent'
import { IntroTwoComponent } from '../components/IntroTwoComponent'
import { IntroThreeComponent } from '../components/IntroThreeComponent'
import { IntroFourComponent } from '../components/IntroFourComponent'
import { EndComponent } from '../components/EndComponent'
import { GenDataComponent } from '../components/GenDataComponent'
import { CardsDataComponent } from '../components/CardsDataComponent'
import IframeComponent from './IframeComponent'
import './createReport.css'
import Container from './layout/container/Container'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

const ProjectDetails = () => {
  const { projectId } = useParams()
  const [jsonDataArray, setJsonDataArray] = useState([])
  const [projectData, setProjectData] = useState(null)
  const [uploadDate, setUploadDate] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const printRef = useRef()
  const fetchDataExecuted = useRef(false)

  const sortAudits = (audits) => {
    const errorArray = []
    const passedArray = []
    const manualArray = []
    const notApplArray = []

    for (const auditKey in audits) {
      const audit = audits[auditKey]
      if (audit.score === 0) {
        errorArray.push(audit)
      } else if (audit.score === 1) {
        passedArray.push(audit)
      } else {
        if (audit.scoreDisplayMode === 'notApplicable') {
          notApplArray.push(audit)
        } else {
          manualArray.push(audit)
        }
      }
    }

    return { errorArray, passedArray, manualArray, notApplArray }
  }

  const fetchData = useCallback(async () => {
    if (fetchDataExecuted.current) return
    fetchDataExecuted.current = true

    try {
      setIsLoading(true)
      const docRef = doc(db, 'accessibility-projects', projectId)
      const docSnap = await getDoc(docRef)

      if (!docSnap.exists()) {
        throw new Error('No such document!')
      }

      const project = docSnap.data().config
      setProjectData(project)

      const projectName = project.name.replace(/\s/g, '_')
      const projectScope = project.scope.replace(/\s/g, '_')
      document.title = `Okb_Interactive-${projectName}-${projectScope}-Web_Accessibility_Analysis`

      console.log('Project data:', project)

      const storage = getStorage()
      const tempData = []

      for (const [index, webpage] of project.webpages.entries()) {
        console.log(`Processing webpage ${index + 1}:`, webpage.url)

        if (!webpage.path || !webpage.webPath) {
          console.error(`Invalid path or webPath for webpage ${index + 1}`)
          continue // Saltar esta página si no tiene un path o webPath válido
        }

        try {
          const storageRef = ref(storage, webpage.path)
          const url = await getDownloadURL(storageRef)
          const uniqueUrl = `${url}&t=${Date.now()}`
          const metadata = await getMetadata(storageRef)

          const response = await fetch(uniqueUrl, { cache: 'no-store' })
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }

          console.log(`Fetching from URL for webpage ${index + 1}: ${uniqueUrl}`)
          console.log(`Path for webpage ${index + 1}: ${webpage.path}`)
          const jsonData = await response.json()
          console.log(`Raw data fetched for webpage ${index + 1}:`, jsonData)
          console.log(`Accessibility score for webpage ${index + 1}:`, jsonData.categories.accessibility.score)

          const processedData = {
            baseUrl: project.url,
            name: webpage.name,
            requestedUrl: `${project.url}${webpage.webPath}`,
            webPath: webpage.webPath,
            ...jsonData,
            ...sortAudits(jsonData.audits)
          }

          processedData.accessibilityScore = Math.round(jsonData.categories.accessibility.score * 100)

          setUploadDate(metadata.timeCreated)

          console.log(`Processed data for webpage ${index + 1}:`, processedData)
          tempData.push({ ...processedData })
        } catch (error) {
          console.error(`Error processing webpage ${index + 1}:`, error)
        }
      }

      console.log('All processed data:', tempData)

      // Check for duplicate data
      const uniqueScores = new Set(tempData.map(data => data.accessibilityScore))
      if (uniqueScores.size !== tempData.length) {
        console.warn('Warning: Duplicate accessibility scores detected!')
      }

      setJsonDataArray(tempData)
    } catch (error) {
      console.error('Failed to fetch data:', error)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [projectId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  console.log('Rendering with jsonDataArray:', jsonDataArray)

  return (
    <Container>
      <div className="px-5 py-6 sm:px-8">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h1 className="text-2xl font-bold">{projectData.name}</h1>
            <p className="mt-1 text-gray-600">
              {new Date(uploadDate).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: 'numeric',
                year: 'numeric'
              })}
              <span className="ml-2 inline-flex items-center gap-2 px-3 py-1 rounded-full border border-blue-300 bg-blue-50 text-blue-600 text-sm font-medium">
                <span className="w-2 h-2 rounded-full bg-blue-500"></span>
                {projectData.environment}
              </span>
            </p>
          </div>
          <div className="ml-4 mt-4 shrink-0">
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-lg bg-purple-600 px-5 py-3 text-lg font-semibold text-white shadow-sm hover:bg-purple-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-700"
                >
                  Download
                  <CheckCircleIcon aria-hidden="true" className="-mr-1 size-6" />
                </button>
              )}
              content={() => printRef.current}
            />
          </div>
        </div>
        <hr className="border-t border-gray-200 my-5" />
      </div>

      <IframeComponent>
        <div className="preview-wrapper">
          <div className="preview-content">
            <div ref={printRef} className="print-container">
              <IntroComponent projectData={projectData} />
              <IntroTwoComponent projectData={projectData} />
              <IntroThreeComponent projectData={projectData} />
              <IntroFourComponent projectData={projectData} />
              {jsonDataArray.map((data, index) => (
                <React.Fragment key={`fragment-${index}`}>
                  <GenDataComponent
                    project={projectData.name}
                    baseURL={projectData.url}
                    score={data.accessibilityScore}
                    path={data.webPath}
                    errorNum={data.errorArray.length}
                    passedNum={data.passedArray.length}
                    notApplNum={data.notApplArray.length}
                    manualNum={data.manualArray.length}
                    manualArray={data.manualArray}
                    scope={projectData.scope}
                    uploadDate={uploadDate}
                  />
                  <CardsDataComponent
                    status="PASSED"
                    project={projectData.name}
                    baseURL={projectData.url}
                    path={data.webPath}
                    errorArray={data.errorArray}
                    passedArray={data.passedArray}
                    notApplArray={data.notApplArray}
                    scope={projectData.scope}
                  />
                  <CardsDataComponent
                    status="ERROR"
                    project={projectData.name}
                    baseURL={projectData.url}
                    path={data.webPath}
                    errorArray={data.errorArray}
                    passedArray={data.passedArray}
                    notApplArray={data.notApplArray}
                    scope={projectData.scope}
                  />
                  <CardsDataComponent
                    status="NOT"
                    project={projectData.name}
                    baseURL={projectData.url}
                    path={data.webPath}
                    errorArray={data.errorArray}
                    passedArray={data.passedArray}
                    notApplArray={data.notApplArray}
                    scope={projectData.scope}
                  />
                </React.Fragment>
              ))}
              <EndComponent />
            </div>
          </div>
        </div>
      </IframeComponent>
    </Container>
  )
}

export default ProjectDetails

import React from 'react'

export const CircleHalfLineImg = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
  <path d="M1.65063 1.05579H32.6506H63.6506V63.0558H32.6506H1.65063V1.05579Z" fill="white"/>
  <path d="M52.0256 32.0558C52.0256 42.7563 43.3512 51.4308 32.6506 51.4308C21.9501 51.4308 13.2756 42.7563 13.2756 32.0558C13.2756 21.3553 21.9501 12.6808 32.6506 12.6808C43.3512 12.6808 52.0256 21.3553 52.0256 32.0558Z" fill="white"/>
  <path d="M32.6506 1.05579H1.65063V63.0558H32.6506M32.6506 1.05579H63.6506V63.0558H32.6506M32.6506 1.05579V63.0558M52.0256 32.0558C52.0256 42.7563 43.3512 51.4308 32.6506 51.4308C21.9501 51.4308 13.2756 42.7563 13.2756 32.0558C13.2756 21.3553 21.9501 12.6808 32.6506 12.6808C43.3512 12.6808 52.0256 21.3553 52.0256 32.0558Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
</svg>

  )
}

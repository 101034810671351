import React from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export default function SearchBar () {
  return (
    <div>
      <div className="relative">
        <input
          id="search"
          name="search"
          type="text"
          placeholder="Search"
          className="block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm leading-tight"
        />
        {/* Magnifying glass icon */}
        <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
          <MagnifyingGlassIcon
            className="h-3.5 w-3.5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        {/* Keyboard shortcut */}
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
            ⌘K
          </kbd>
        </div>
      </div>
    </div>
  )
}

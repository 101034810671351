import React from 'react'
import { UpsideDownVCompImg } from '../img/UpsideDownVCompImg'
import { TwoLoopsImg } from '../img/TwoLoopsImg'
import { CircleLineImg } from '../img/CircleLineImg'
import { HourglassBigImg } from '../img/HourglassBigImg'
import { StarCompImg } from '../img/StarCompImg'
import { SquareLoopsImg } from '../img/SquareLoopsImg'
import { PineTreeImg } from '../img/PineTreeImg'
import { SunImg } from '../img/SunImg'
import { ASquareImg } from '../img/ASquareImg'
import styles from './intro.module.css'
import { HavasGroupBigImg } from '../img/HavasGroupBigImg'

export const IntroComponent = ({ projectData }) => {
  if (!projectData) {
    return <div>Loading...</div>
  }

  return (
    <div className={styles['HAV-web']}>
      <div className={styles.squares}>
        <div className={styles['wrap-squares']}>
          <div className={styles['square-row']}>
            <div className={styles.element}>
              <HourglassBigImg width={'248'} height={'248'} />
            </div>
            <div className={styles.element}>
              <UpsideDownVCompImg />
            </div>
            <div className={styles['element-square-loops']}>
              <SquareLoopsImg />
            </div>
          </div>
          <div className={styles['square-row']}>
            <div className={styles['element-pine-tree']}>
              <PineTreeImg />
            </div>
            <div className={styles.element}>
              <TwoLoopsImg width={'248'} height={'248'} />
            </div>
            <div className={styles.element}>
              <SunImg />
            </div>
          </div>

          <div className={styles['square-row']}>
            <div className={styles.element}>
              <CircleLineImg width={'248'} height={'248'} />
            </div>
            <div className={styles.element}>
              <ASquareImg />
            </div>
            <div className={styles.element}>
              <StarCompImg />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.frame}>
        <div className={styles.div}>
          <HavasGroupBigImg className={styles.group} alt="Group" src="group.png" />
          <div className={styles['text-wrapper']}>Global Client Technology</div>
        </div>
        <div className={styles['div-2']}>
          <div className={styles['div-3']}>
            <div className={styles.div}>
              <div className={styles['text-wrapper-2']}>Project</div>
              <div className={styles['text-wrapper-3']}>{projectData.name}</div>
            </div>
            <div className={styles.div}>
              <div className={styles['text-wrapper-2']}>Scope</div>
              <div className={styles['text-wrapper-3']}>{projectData.scope}</div>
            </div>
          </div>
          <div className={styles['web-accessibility']}>
            Web <br />
            accessibility <br />
            analysis
          </div>
          <div className={styles['NOTICE-proprietary-wrapper']}>
            <p className={styles['NOTICE-proprietary']}>
              <span className={styles.span}>
                NOTICE: Proprietary &amp; Confidential
                <br />
                All the content of this document (text, figures, lists, financial information, graphics, design, diagrams,
                as well as other graphic elements and/or audio and videos), whichever the format used (paper or
                electronic), is confidential and proprietary to Havas Media Network. This document includes ideas and
                information based on the experience, know-how, intellectual/creative effort of Havas Media Network. For
                these reasons, this material shall not be used, reproduced, copied, disclosed, transmitted, transformed,
                commercialized or communicated, in whole or in part, neither to third parties nor to the public, without
                the express and written consent of Havas Media Network.
                <br />
                <br />
              </span>
              <span className={styles['text-wrapper-3']}>
                Havas Media Network © All rights reserved
                <br />
              </span>
              <span className={styles.span}>
                This presentation is not a contractual proposal and has no binding effects for any Havas Media Network
                company until a final and written contract is entered into between the parties.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

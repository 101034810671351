import React from 'react'
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation } from 'react-router-dom'

import Home from './pages/Home'
import AccessibilityData from './pages/AccessibilityData'
import CreateReportComponent from './pages/CreateReportComponent'
import ListProjectsComponent from './pages/ListProjectsComponent'
import ProjectDetails from './components/ProjectDetails'
import Sidebar from './components/layout/sidebar/Sidebar'
import CreateReportPage from './pages/CreateReportPage'

const BaseLayout = () => {
  const location = useLocation()

  const pageName = () => {
    switch (location.pathname) {
      case '/':
        return 'Home'
      case '/list-projects':
        return 'Accessibility Dashboard'
      case '/tasks':
        return 'Tasks Dashboard'
      default:
        return 'Document Detail'
    }
  }

  return (
    <div>
      <Sidebar />
      <main className="py-10 lg:pl-72 min-h-screen bg-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Encabezado */}
          <div className="py-5">
            <h1 className="text-3xl font-bold text-gray-900">{pageName()}</h1>
            <p className="mt-1 text-xl text-gray-600">
              Effortlessly track and analyze web and task accessibility reports
            </p>
          </div>
          <Outlet />
        </div>
      </main>
    </div>
  )
}

function App () {
  return (
    <Router>
      <Routes>
        {/* Wrap all relevant routes with BaseLayout */}
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Home />} /> {/* Default route */}
          <Route path="create" element={<CreateReportComponent />} />
          <Route path="data" element={<AccessibilityData />} />
          <Route path="list-projects" element={<ListProjectsComponent />} />
          <Route path="project/:projectId" element={<ProjectDetails />} />
          <Route path="create-report" element={<CreateReportPage />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App

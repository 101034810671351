import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Calendar from './Calendar'
import { handleDateChange, dateOptions } from '../../utils/dateUtils'
import { classNames } from '../../utils/classNames'

export default function CalendarCard ({ startDate, setStartDate, endDate, setEndDate, setIsCalendarOpen }) {
  const [dateOption, setDateOption] = useState('All time')

  console.log('dates test', startDate, endDate)

  return (
    <div className="bg-white shadow-lg rounded-lg w-[800px] p-4">
      {/* Main Container */}
      <div className="flex">
        {/* Side List */}
        <div className="w-1/4 pr-4 border-r border-gray-200">
          <ul className="space-y-2 text-gray-700 text-xs">
            {dateOptions.map((option, idx) => (
            <li
            key={idx}
            className={classNames(
              'cursor-pointer rounded-md px-2 py-1',
              option === dateOption ? 'bg-gray-100 text-black font-semibold' : 'hover:bg-gray-100 text-gray-700'
            )}
            onClick={() => setDateOption(option)}
          >
            {option}
          </li>

            ))}
          </ul>
        </div>

        {/* Calendars */}
        <div className="flex-1 pl-4 pr-0">
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 rounded-lg shadow-sm">
              <Calendar />
            </div>
            <div className="p-4 rounded-lg shadow-sm">
              <Calendar />
            </div>
          </div>
          {/* Inputs */}
          <div className="flex justify-between">
            <div className="flex mt-4 px-4 space-x-2">
              <input
                type="text"
                placeholder="Select date"
                className="w-24 p-2 rounded-md border border-gray-300 text-xs"
              />
            <input type="text" placeholder="Select date" className="w-24 p-2 rounded-md border border-gray-300 text-xs" />
          </div>
          {/* Action Buttons */}
          <div className="flex justify-end mt-4 space-x-2">
            <button className="px-4 py-2 bg-gray-200 text-gray-700 text-xs rounded-md hover:bg-gray-300" onClick={() => setIsCalendarOpen((prev) => !prev)}>
              Cancel
            </button>
            <button className="px-4 py-2 bg-indigo-600 text-white text-xs rounded-md hover:bg-indigo-500" onClick={() => {
              handleDateChange(dateOption, setStartDate, setEndDate)
              setIsCalendarOpen(false)
            }}>
              Apply
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CalendarCard.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  setEndDate: PropTypes.func.isRequired,
  setIsCalendarOpen: PropTypes.func.isRequired
}

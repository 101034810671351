import React from 'react'

export const HalfCirclesImg = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
  <rect width="62" height="62" transform="translate(1.65063 1.5)" fill="white"/>
  <rect x="1.65063" y="1.5" width="62" height="62" fill="#F9F9F9" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M1.65063 63.5C1.65063 46.3792 15.5298 32.5 32.6506 32.5C49.7715 32.5 63.6506 46.3792 63.6506 63.5H1.65063Z" fill="white" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M63.6506 1.5C63.6506 18.6208 49.7715 32.5 32.6506 32.5C15.5298 32.5 1.65064 18.6208 1.65064 1.49999L63.6506 1.5Z" fill="white" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
</svg>
  )
}

import React from 'react'
import { GreenCircleImg } from '../img/GreenCircleImg'
import { LinesBoxImg } from '../img/LinesBoxImg'
import { HourglassImg } from '../img/HourglassImg'
import { HavasGroupImg } from '../img/HavasGroupImg'
import './sidebarUrlTest.css'

export const SidebarUrlTest = ({ project, baseUrl, path, scope }) => {
  return (
    <div className="sidebar-url-test">
      <div className="frame">
        <div className="div">
          <HavasGroupImg className="group" alt="Group" />
          <div className="text-wrapper">Global Client Technology</div>
        </div>
        <div className="text-wrapper-2">URL analysis</div>
      </div>
      <div className="frame">
        <div className="frame-wrapper">
          <div className="frame-2">
            <div className="frame-3">
              <div className="div">
                <div className="text-wrapper-3">Project</div>
                <div className="text-wrapper-4">{project}</div>
              </div>
              <div className="div">
                <div className="text-wrapper-3">Scope</div>
                <div className="text-wrapper-4">{scope}</div>
              </div>
              <div className="div">
                <div className="text-wrapper-3">Base URL</div>
                {/* Wrap the base URL in an <a> tag */}
                <a href={baseUrl} target="_blank" rel="noopener noreferrer" className="text-wrapper-4">
                  {baseUrl}
                </a>
              </div>
              <div className="div">
                <div className="text-wrapper-3">Path</div>
                {/* Wrap the path in an <a> tag, combining it with the base URL */}
                <a href={`${baseUrl}${path}`} target="_blank" rel="noopener noreferrer" className="text-wrapper-4">
                  {path}
                </a>
              </div>
            </div>
            <div className="web-accessibility">
              Web <br />
              accessibility <br />
              analysis
            </div>
            {/* <DownArrowImg className="vector" alt="Vector" /> */}
          </div>
        </div>
      </div>
      <div className="frame-4">
        <GreenCircleImg className="element" />
        <div className="rectangle" />
        <div className="rectangle" />
        <HourglassImg className="img" alt="Element" />
        <div className="rectangle" />
        <div className="rectangle" />
        <div className="rectangle" />
        <div className="rectangle" />
        <LinesBoxImg className="instance-119" />
        <LinesBoxImg className="instance-119-instance" />
        <div className="rectangle" />
        <HourglassImg className="element-2" alt="Element" />
        <div className="rectangle" />
        <GreenCircleImg className="instance-143" />
        <div className="rectangle" />
        <div className="rectangle" />
        <LinesBoxImg className="instance-119" />
        <GreenCircleImg className="instance-143" />
        <div className="rectangle" />
        <div className="rectangle" />
        <div className="rectangle" />
        <GreenCircleImg className="instance-143-instance" />
        <div className="rectangle" />
        <div className="rectangle" />
        <div className="rectangle" />
      </div>
    </div>
  )
}

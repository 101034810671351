import React from 'react'
import { HourglassBigImg } from '../img/HourglassBigImg'
import { TwoCirclesImg } from '../img/TwoCirclesImg'
import { TwoLoopsImg } from '../img/TwoLoopsImg'
import { StarImg } from '../img/StarImg'
import { CircleLineImg } from '../img/CircleLineImg'
import { UpsideDownVImg } from '../img/UpsideDownVImg'
import { HavasGroupImg } from '../img/HavasGroupImg'
import styles from './end.module.css'

export const EndComponent = () => {
  return (
        <div className={styles['HAV-web']}>
            <div className={styles.frame}>
                <div className={styles['group-wrapper']}>
                    <div className={styles.group}>
                        <div className={styles['overlap-group']}>
                            <StarImg className={styles.vector} alt="Vector" />
                            <div className={styles.rectangleS} />
                        </div>
                    </div>
                </div>
                <div className={styles.div}>
                    <div className={styles['div-wrapper']}>
                        <div className={styles['overlap-group-wrapper']}>
                            <div className={styles['overlap-group']}>
                                <CircleLineImg className={styles.img} alt="Vector" width={'288'} height={'288'} />
                                <div className={styles.rectangle} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.div}>
                    <TwoCirclesImg className={styles.element} />
                    <div className={styles.rectangle} />
                </div>
                <div className={styles.div}>
                    <div className={styles['group-2']}>
                        <div className={styles['rectangle-wrapper']}>
                            <TwoLoopsImg className={styles['rectangle-2']} width={'288'} height={'288'} />
                            <div className={styles.rectangle} />
                        </div>
                    </div>
                </div>
                <div className={styles.div}>
                    <HourglassBigImg className={styles['element-2']} alt="Element" width={'288'} height={'288'} />
                    <div className={styles.rectangle} />
                </div>
                <div className={styles.div}>
                    <div className={styles['group-3']}>
                        <div className={styles['overlap-group-2']}>
                            <UpsideDownVImg className={styles['vector-2']} alt="Vector" width={'288'} height={'288'} />
                            <div className={styles['rectangle-3']} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['frame-wrapper']}>
                <div className={styles['frame-3']}>
                    <div className={styles['frame-4']}>
                        <HavasGroupImg className={styles['group-4']} alt="Group" />
                        <div className={styles['text-wrapper-2']}>Global Client Technology</div>
                    </div>
                    <div className={styles['text-wrapper-3']}>Web accessibility analysis</div>
                </div>
            </div>
            <div className={styles['NOTICE-proprietary-wrapper']}>
                <p className={styles['NOTICE-proprietary']}>
                    <span className={styles.span}>
                        NOTICE: Proprietary & Confidential
                        <br />
                        All the content of this document (text, figures, lists, financial information, graphics, design, diagrams,
                        as well as other graphic elements and/or audio and videos), whichever the format used (paper or electronic),
                        is confidential and proprietary to Havas Media Network. This document includes ideas and information based
                        on the experience, know-how, intellectual/creative effort of Havas Media Network. For these reasons, this
                        material shall not be used, reproduced, copied, disclosed, transmitted, transformed, commercialized or
                        communicated, in whole or in part, neither to third parties nor to the public, without the express and
                        written consent of Havas Media Network.
                        <br />
                        <br />
                    </span>
                    <span className={styles['text-wrapper-4']}>
                    Havas Media Network © All rights reserved
                        <br />
                    </span>
                    <span className={styles.span}>
                        This presentation is not a contractual proposal and has no binding effects for any Havas Media Network
                        company until a final and written contract is entered into between the parties.
                    </span>
                </p>
            </div>
        </div>
  )
}

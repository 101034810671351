import React from 'react'
export const TiltedPaperBoatImg = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M1.65063 63.0558H63.6506L32.6506 32.0558L1.65063 1.05579V63.0558Z" fill="white"/>
  <path d="M63.6506 1.05579H1.65063L32.6506 32.0558L63.6506 63.0558V1.05579Z" fill="white"/>
  <path d="M1.65063 1.05579H63.6506V63.0558M1.65063 1.05579V63.0558M1.65063 1.05579L32.6506 32.0558M63.6506 63.0558H1.65063M63.6506 63.0558L32.6506 32.0558M1.65063 63.0558L32.6506 32.0558" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M63.6506 1.05579H1.65063L32.6506 32.0558L63.6506 63.0558V1.05579Z" fill="#D32D1F" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

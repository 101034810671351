import React from 'react'
import {
  HomeIcon,
  ClipboardDocumentCheckIcon,
  ListBulletIcon,
  ChevronUpDownIcon
} from '@heroicons/react/24/outline'
import SearchBar from '../../common/SearchBar'
import { Link, useLocation } from 'react-router-dom'

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon },
  { name: 'Projects', href: '/list-projects', icon: ListBulletIcon },
  { name: 'Tasks', href: '/list-tasks', icon: ClipboardDocumentCheckIcon }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Sidebar () {
  const location = useLocation()

  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                alt="Your Company"
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                className="h-8 w-auto"
              />
            </div>
            <SearchBar />
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                <ul className="space-y-1">
                {navigation.map((item) => {
                  const isCurrent = item.href === location.pathname
                  return (
    <li key={item.name}>
      <Link
        to={item.href}
        className={classNames(
          isCurrent
            ? 'bg-gray-50 text-indigo-600'
            : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold'
        )}
      >
        <item.icon
          aria-hidden="true"
          className={classNames(
            isCurrent
              ? 'text-indigo-600'
              : 'text-gray-400 group-hover:text-indigo-600',
            'h-5 w-5 shrink-0'
          )}
        />
        {item.name}
      </Link>
    </li>
                  )
                })}

                  </ul>
                </li>
                {/* Profile */}
                <li className="mt-auto">
                  <a
                    href="/"
                    className="relative flex items-center gap-x-2 rounded-lg border border-gray-200 bg-white px-2 mb-4 py-2 shadow-sm hover:bg-gray-50"
                  >
                    {/* Avatar */}
                    <img
                      alt=""
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      className="h-10 w-10 rounded-full"
                    />
                    {/* Profile Info */}
                    <div className="flex flex-col">
                      <span aria-hidden="true" className="text-sm font-semibold text-gray-900">
                        Olivia Rhye
                      </span>
                      <span className="text-sm text-gray-500">olivia@untitledui.com</span>
                    </div>
                    {/* Chevron Icon */}
                    <ChevronUpDownIcon
                      className="absolute top-2 right-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

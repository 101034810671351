import React from 'react'
import { OverviewUrl } from './OverviewUrl'
import { SidebarUrlTest } from '../shared/SidebarUrlTest'
import styles from './genData.module.css'
import { ManualTestLabel } from '../shared/ManualTestLabel'

export const GenDataComponent = ({ project, baseURL, score, path, errorNum, passedNum, notApplNum, manualNum, manualArray, scope, uploadDate }) => {
  const limitedManualArray = manualArray.slice(0, 8)

  return (
    <div className={styles['HAV-web']}>
      <SidebarUrlTest
        className={styles['sidebar-url-test-instance']} project={project} baseUrl={baseURL} path={path} scope={scope}
      />
      <div className={styles['title-manual']}>
        <p className={styles['text-wrapper-15']}>Analyzed opportunities to improve the accessibility</p>
      </div>
      <p className={styles['these-checks']}>
        <span className={styles.span}>
          These checks highlight opportunities to improve the accessibility of the web app. Automatic detection used
          to detect a subset of issues{' '}
        </span>
      </p>
      <div className={styles['frame-6']} >
        <p className={styles['manual-testing']} >
          <span className={styles['text-wrapper-16']}>Manual testing during development needed:</span>
        </p>
        <div className={styles['frame-7']} >
          {limitedManualArray.map((item, index) => (
            <ManualTestLabel
              key={index} // Use a unique key for each child in a list
              text={item.title} // Pass the title from each item in the limited array
              description={item.description}
              className={styles['div-wrapper']}
            />
          ))}
        </div>
      </div>
      <div className={styles['container-data']}>
        <p className={styles.p}>Captured at {new Date(uploadDate).toLocaleString()}</p>
        <p className={styles['emulated-desktop']}>
          Emulated Desktop with Lighthouse 11.2.0
          <br />
          Unthrottled CPU/Memory Power: 1771 CPU throttling: 1x slowdown (Simulated) Screen emulation: 412x823, DPR 1.75
          Axe version: 4.8.1
          <br />
          Single page load
          <br />
          Initial page load
        </p>
      </div>
      <div className={styles['overview-div']}>
        <OverviewUrl className={styles['overview-url-container']} score={score} baseUrl={baseURL} path={path} errorNum={errorNum}
        passedNum={passedNum} manualNum={manualNum} notApplNum={notApplNum}/>
      </div>
    </div>
  )
}

import React from 'react'

export const SquareHalfLineImg = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
  <path d="M1.65063 1.05579H63.6506V63.0558H1.65063V1.05579Z" fill="white"/>
  <path d="M63.6506 1.05579H1.65063V63.0558M63.6506 1.05579V63.0558H1.65063M63.6506 1.05579L32.6506 32.0558M1.65063 63.0558L17.1506 47.5558L32.6506 32.0558M32.6506 32.0558L17.3057 16.4485M32.6506 32.0558L47.8901 47.5558" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M63.6506 63.0558V1.05579L32.6506 32.0558L17.1506 47.5558L1.65063 63.0558H63.6506Z" fill="#D32D1F"/>
  <path d="M32.6506 32.0558L63.6506 1.05579V63.0558H1.65063L17.1506 47.5558L32.6506 32.0558ZM32.6506 32.0558L47.8901 47.5558" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
</svg>
  )
}

import React from 'react'

export const GreenCircleImg = () => {
  return (
<svg width="52.4" height="52.4" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 52.9967H53V0.996704H1V52.9967Z" fill="white"/>
<path d="M43.25 26.9967C43.25 18.0221 35.9746 10.7467 27 10.7467C18.0254 10.7467 10.75 18.0221 10.75 26.9967C10.75 35.9713 18.0254 43.2467 27 43.2467C35.9746 43.2467 43.25 35.9713 43.25 26.9967Z" fill="white"/>
<path d="M1 52.9967H53V0.996704H1V52.9967Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
<path d="M43.25 26.9967C43.25 18.0221 35.9746 10.7467 27 10.7467C18.0254 10.7467 10.75 18.0221 10.75 26.9967C10.75 35.9713 18.0254 43.2467 27 43.2467C35.9746 43.2467 43.25 35.9713 43.25 26.9967Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
<path d="M43.25 26.9967C43.25 18.0221 35.9746 10.7467 27 10.7467C18.0254 10.7467 10.75 18.0221 10.75 26.9967C10.75 35.9713 18.0254 43.2467 27 43.2467C35.9746 43.2467 43.25 35.9713 43.25 26.9967Z" fill="#A1EAB1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'Sin fecha'
  if (timestamp.toDate) {
    return new Date(timestamp.toDate()).toLocaleString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }
  return 'Sin fecha'
}

export const dateOptions = [
  'Today',
  'Yesterday',
  'This week',
  'Last week',
  'This month',
  'Last month',
  'This year',
  'Last year',
  'All time'
]

export function handleDateChange (dateOption, setStartDate, setEndDate) {
  switch (dateOption) {
    case 'Today':
      setStartDate(new Date())
      setEndDate(new Date())
      break
    case 'Yesterday':
      {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        setStartDate(yesterday)
        setEndDate(yesterday)
      }
      break
    case 'This week':
      {
        const now = new Date()
        // Get the Monday of the current week (assuming Monday start)
        const weekStart = new Date(now)
        const day = weekStart.getDay() // Sunday=0, Monday=1, ...
        const mondayIndex = (day === 0) ? -6 : (1 - day)
        weekStart.setDate(weekStart.getDate() + mondayIndex)
        // Get the Saturday of that same week
        const weekEnd = new Date(weekStart)
        weekEnd.setDate(weekEnd.getDate() + 6)

        setStartDate(weekStart)
        setEndDate(weekEnd)
      }
      break
    case 'Last week':
      {
        const now = new Date()
        const weekStart = new Date(now)
        const day = weekStart.getDay() // Sunday=0, Monday=1, ...
        const mondayIndex = (day === 0) ? -6 : (1 - day)

        // Move to this week's Monday
        weekStart.setDate(weekStart.getDate() + mondayIndex)

        // Now go back one full week to get last week's Monday
        weekStart.setDate(weekStart.getDate() - 7)

        // Create a new Date for weekEnd based on weekStart
        const weekEnd = new Date(weekStart)
        weekEnd.setDate(weekEnd.getDate() + 6)

        setStartDate(weekStart)
        setEndDate(weekEnd)
      }
      break
    case 'This month': {
      const now = new Date()
      const monthStart = new Date(now.getFullYear(), now.getMonth(), 1)
      const monthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0)

      setStartDate(monthStart)
      setEndDate(monthEnd)
    }
      break
    case 'Last month': {
      const now = new Date()
      const monthStart = new Date(now.getFullYear(), now.getMonth(), 1)

      monthStart.setMonth(monthStart.getMonth() - 1)
      const monthEnd = new Date(now.getFullYear(), monthStart.getMonth() + 1, 0)

      setStartDate(monthStart)
      setEndDate(monthEnd)
    }
      break
    case 'This year': {
      const now = new Date()
      const yearStart = new Date(now.getFullYear(), 0, 1) // January 1st of the current year
      const yearEnd = new Date(now.getFullYear(), 11, 31) // December 31st of the current year

      setStartDate(yearStart)
      setEndDate(yearEnd)
    }
      break
    case 'Last year': {
      const now = new Date()
      const yearStart = new Date(now.getFullYear() - 1, 0, 1) // January 1st of last year
      const yearEnd = new Date(now.getFullYear() - 1, 11, 31) // December 31st of last year

      setStartDate(yearStart)
      setEndDate(yearEnd)
    }
      break
    case 'All time': {
      const earliestDate = new Date(0) // Unix epoch start
      const now = new Date()

      setStartDate(earliestDate)
      setEndDate(now)
    }
      break
    default: {
      const earliestDate = new Date(0) // Unix epoch start
      const now = new Date()

      setStartDate(earliestDate)
      setEndDate(now)
    }
      break
  }
}

// Get current month's days
export const getDaysInMonth = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const firstDay = new Date(year, month, 1)
  const lastDay = new Date(year, month + 1, 0)
  const daysInMonth = []

  // Add previous month's days
  const firstDayOfWeek = firstDay.getDay() || 7 // Convert Sunday (0) to 7
  const prevMonthDays = firstDayOfWeek - 1
  const prevMonth = new Date(year, month, 0)
  for (let i = prevMonthDays; i > 0; i--) {
    daysInMonth.push({
      date: new Date(year, month - 1, prevMonth.getDate() - i + 1).toISOString().split('T')[0],
      isCurrentMonth: false
    })
  }

  // Add current month's days
  for (let i = 1; i <= lastDay.getDate(); i++) {
    const date = new Date(year, month, i)
    daysInMonth.push({
      date: date.toISOString().split('T')[0],
      isCurrentMonth: true,
      isToday: date.toDateString() === new Date().toDateString()
    })
  }

  // Add next month's days
  const remainingDays = 42 - daysInMonth.length // 6 rows × 7 days
  for (let i = 1; i <= remainingDays; i++) {
    daysInMonth.push({
      date: new Date(year, month + 1, i).toISOString().split('T')[0],
      isCurrentMonth: false
    })
  }

  return daysInMonth
}

export const daysInWeek = [
  'Mo',
  'Tu',
  'We',
  'Th',
  'Fr',
  'Sa',
  'Su'
]

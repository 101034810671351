import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from '../components/layout/container/Container'
import { MagnifyingGlassIcon, ArrowLeftIcon, ArrowRightIcon, ChevronUpDownIcon, CalendarIcon, FunnelIcon } from '@heroicons/react/24/outline'
import CalendarCard from '../components/common/CalendarCard'
import { useProjects } from '../hooks/useProjects'
import { formatTimestamp } from '../utils/dateUtils'
import { paginate } from '../utils/paginationUtils'

const ListProjectsComponent = () => {
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortField, setSortField] = useState('name')
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [startDate, setStartDate] = useState(new Date(0))
  const [endDate, setEndDate] = useState(new Date())
  const navigate = useNavigate()

  const projects = useProjects(sortField, sortOrder)

  const handleNavigateToCreate = () => {
    navigate('/create')
  }

  const handleNavigateToDetails = (projectId) => {
    navigate(`/project/${projectId}`)
  }

  // Then at render time, you apply the filters:
  const filteredProjects = useMemo(() => {
    return projects
      .filter(project => {
        if (project.timestamp?.toDate) {
          const projectDate = project.timestamp.toDate()
          return projectDate >= startDate && projectDate <= endDate
        }
        return true
      })
      .filter(project =>
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.scope.toLowerCase().includes(searchTerm.toLowerCase())
      )
  }, [projects, startDate, endDate, searchTerm])

  const currentPageProjects = paginate(filteredProjects, currentPage, 7)

  return (
    <>
      <div className="pb-4">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </div>
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-72 rounded-xl border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6 h-12"
            />
          </div>

          {/* Buttons */}
          <div className="relative">
          <div className="flex items-center gap-2">
              {/* "Select dates" Button */}
              <button
                onClick={() => setIsCalendarOpen((prev) => !prev)} // Toggle modal visibility
                className="flex items-center gap-2 px-3 py-1.5 text-sm border border-gray-300 rounded-lg bg-white hover:bg-gray-50 h-12"
              >
              <CalendarIcon className="h-5 w-5" /> Select dates
            </button>
            <button className="flex items-center gap-2 px-3 py-1.5 text-sm border border-gray-300 rounded-lg bg-white hover:bg-gray-50 h-12">
              <FunnelIcon className="h-5 w-5" /> Filters
            </button>
            </div>

            {/* Calendar modal */}
            {isCalendarOpen && (
              <div className="absolute top-full mt-2 right-0 bg-white border border-gray-300 shadow-lg rounded-lg z-50">
                <CalendarCard startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}
                isCalendarOpen={isCalendarOpen} setIsCalendarOpen={setIsCalendarOpen}/>
              </div>
            )}
          </div>
        </div>
      </div>
      <Container>
        <div className="p-6">
          {/* Header section */}
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-xl font-semibold">Web apps analysis</h1>
            <div className="flex gap-2">
              <button
                onClick={handleNavigateToCreate}
                className="inline-flex items-center rounded-lg bg-purple-600 px-4 py-3 text-lg font-semibold text-white shadow-sm hover:bg-purple-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-700"
              >
                New App analysis
              </button>
            </div>
          </div>

          {/* Projects list */}
          <ul className="space-y-2 pt-2">
            {/* Column headers */}
            <li className="flex items-start justify-between px-1 py-2">
              <div className="flex items-center-left">
                <span
                  onClick={() => {
                    setSortField('name')
                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                  }}
                  className="text-sm text-gray-500 flex items-center gap-1 cursor-pointer hover:text-gray-700"
                >
                  Project <ChevronUpDownIcon className="h-4 w-4"/>
                </span>
              </div>
              <div className="flex items-center gap-4">
                <span
                  onClick={() => {
                    setSortField('urls')
                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                  }}
                  className="text-sm text-gray-500 w-[60px] text-left flex items-center gap-1 cursor-pointer hover:text-gray-700"
                >
                  URLs <ChevronUpDownIcon className="h-4 w-4"/>
                </span>
                <span className="text-sm text-gray-500 w-[120px] text-left flex items-center gap-1">Environment <ChevronUpDownIcon className="h-4 w-4"/></span>
                <div className="w-[40px]"></div>
              </div>
            </li>

            <hr className="border-t border-gray-200" />

            {/* Project items */}
            {currentPageProjects
              .map((project) => (
                <li key={project.id}
                  className="flex items-center justify-between px-6 py-4 hover:bg-gray-50">
                  <div className="flex items-center gap-4">
                    <div className="w-8 h-8">
                      <img src="/pdf-icon.svg" alt="PDF" className="w-full h-full" />
                    </div>
                    <div>
                      <h2 className="font-medium">{project.name}</h2>
                      <p className="text-sm text-gray-500">{formatTimestamp(project.timestamp)}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <span className="px-3 py-1 text-sm border border-green-700 text-green-700 rounded-full">
                      {project.webpages.length}
                    </span>
                    <span className="inline-flex items-center gap-2 px-3 py-1 rounded-full border border-blue-300 bg-blue-50 text-blue-600 text-sm font-medium">
                      {project.environment ? project.environment : 'No environment'}
                    </span>
                    <button
                      onClick={() => handleNavigateToDetails(project.id)}
                      className="font-bold text-purple-600 hover:text-purple-800"
                    >
                      View
                    </button>
                  </div>
                </li>
              ))}
          </ul>

          {/* Pagination */}
          <div className="flex items-center justify-between mt-4 pt-4 border-t">
            <button
              onClick={() => setCurrentPage(prev => prev - 1)}
              disabled={currentPage === 0}
              className={`flex items-center gap-2 px-4 py-2 text-sl font-semibold border rounded-lg ${
                currentPage === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'
              }`}
            >
              <ArrowLeftIcon className="h-5 w-5" /> Previous
            </button>
            <div className="flex items-center gap-2">
              {Array.from({ length: Math.ceil(filteredProjects.length / 7) }, (_, i) => {
                const totalPages = Math.ceil(filteredProjects.length / 7)

                // Mostrar primeras 3 páginas, últimas 3 páginas, página actual y adyacentes
                if (
                  i < 3 || // Primeras 3 páginas
                  i >= totalPages - 3 || // Últimas 3 páginas
                  i === currentPage || // Página actual
                  i === currentPage - 1 || // Página anterior
                  i === currentPage + 1 // Página siguiente
                ) {
                  return (
                    <button
                      key={i}
                      onClick={() => setCurrentPage(i)}
                      className={`px-3 py-1 text-sm rounded-lg ${
                        currentPage === i ? 'bg-purple-100 text-purple-600' : 'hover:bg-gray-50'
                      }`}
                    >
                      {i + 1}
                    </button>
                  )
                } else if (
                  (i === 3 && currentPage < totalPages - 4) || // Puntos después de primeras páginas
                  (i === totalPages - 4 && currentPage > 2) // Puntos antes de últimas páginas
                ) {
                  return <span key={i} className="px-2">...</span>
                }
                return null
              })}
            </div>
            <button
              onClick={() => setCurrentPage(prev => prev + 1)}
              disabled={currentPage >= Math.ceil(filteredProjects.length / 7) - 1}
              className={`flex items-center gap-2 px-4 py-2 text-sl font-semibold border rounded-lg ${
                currentPage >= Math.ceil(filteredProjects.length / 7) - 1
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-gray-50'
              }`}
            >
              Next<ArrowRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ListProjectsComponent

import React from 'react'

export const SquareLineImg = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
  <path d="M1.65063 1.5H63.6506V63.5H1.65063V1.5Z" fill="white"/>
  <path d="M63.6506 1.5H1.65063V63.5H63.6506V1.5ZM63.6506 1.5L32.6506 32.5" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
</svg>
  )
}

import React from 'react'
import { useForm } from 'react-hook-form'

const ConfigForm = ({ onSubmit, isDisabled }) => {
  const { register, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      url: '',
      scope: '',
      environment: ''
    }
  })

  return (
    <div className="space-y-12 sm:space-y-16">
      <div>
        <div className="space-y-8 sm:space-y-0 sm:pb-0">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 pb-6">
            <label className="block text-lg font-medium text-gray-900 sm:pt-1.5">
              Project details *
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <label className="block text-al font-medium text-gray-600 sm:pt-1.5">Name of project *</label>
              <input
                type="text"
                {...register('name', { required: true })}
                placeholder="Project Name"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-purple-600 sm:max-w-md sm:text-sm/6"
              />
              {errors.name && <span className="mt-1 text-sm text-red-500">This field is required</span>}

              <label className="block text-sl font-medium text-gray-600 sm:pt-1.5 mt-4">
                Scope Project *
              </label>
              <input
                type="text"
                {...register('scope', { required: true })}
                placeholder="Scope"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-purple-600 sm:max-w-md sm:text-sm/6"
              />
              {errors.scope && <span className="mt-1 text-sm text-red-500">This field is required</span>}
              <p className="mt-1 text-sm/6 text-gray-500">Ex. 2025 Q1 production analysis</p>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 border-t border-gray-900/10 pb-12 mt-12 sm:items-start sm:gap-4 sm:py-6">
            <label className="block text-lg font-medium text-gray-900 sm:pt-1.5">
              URL *
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <input
                type="url"
                {...register('url', { required: true })}
                placeholder="Main URL of project"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-purple-600 sm:max-w-md sm:text-sm/6"
              />
              {errors.url && <span className="mt-1 text-sm text-red-500">This field is required</span>}
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 border-t border-gray-900/10 pb-12 mt-12 sm:items-start sm:gap-4 sm:py-6">
            <label className="block text-lg font-medium text-gray-900 sm:pt-1.5">
              Environment *
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <div className="flex flex-col space-y-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="Production"
                    {...register('environment', { required: true })}
                    className="form-radio size-4 text-gray-600 checked:bg-purple-600 checked:hover:bg-purple-600"
                  />
                  <span className="ml-2 text-sl">Production</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="Pre-Production"
                    {...register('environment', { required: true })}
                    className="form-radio size-4 text-gray-600 checked:bg-purple-600 checked:hover:bg-purple-600"
                  />
                  <span className="ml-2 text-sl">Pre-Production</span>
                </label>
              </div>
              {errors.environment && <span className="mt-1 text-sm text-red-500">Please select an environment</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfigForm

import React from 'react'

export const LinesBoxImg = () => {
  return (
<svg width="52.4" height="52.4" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.35165 1.44739H27.5563H53.7609V27.652V53.8566H27.5563H1.35165V27.652V1.44739Z" fill="white"/>
<path d="M1.35165 1.44739L53.7609 53.8566M1.35165 1.44739H27.5563M1.35165 1.44739V27.652M53.7609 53.8566V27.652M53.7609 53.8566H27.5563M27.5563 1.44739H53.7609V27.652M27.5563 1.44739L53.7609 27.652M1.35165 27.652V53.8566H27.5563M1.35165 27.652L27.5563 53.8566" stroke="black" strokeWidth="1.83892" strokeLinejoin="round"/>
</svg>
  )
}

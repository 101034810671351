import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

const IframeComponent = ({ children }) => {
  const iframeRef = useRef(null)
  const [iframeDocument, setIframeDocument] = useState(null)

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentDocument
      if (doc) {
        setIframeDocument(doc)

        // Inyectar las hojas de estilo
        const styleTags = Array.from(document.head.getElementsByTagName('style'))
        // exclude first style tag
        styleTags.shift()
        styleTags.forEach(tag => {
          const newTag = doc.createElement('style')
          newTag.innerHTML = tag.innerHTML
          doc.head.appendChild(newTag)
        })

        const linkTags = Array.from(document.head.getElementsByTagName('link'))
        linkTags.forEach(tag => {
          if (tag.rel === 'stylesheet') {
            const newTag = doc.createElement('link')
            newTag.rel = 'stylesheet'
            newTag.href = tag.href
            doc.head.appendChild(newTag)
          }
        })
      }
    }
  }, [])

  return (
    <div className="flex justify-center items-center">
      <div className="relative w-full max-w-[1440px] h-auto overflow-hidden">
        <div
          className="w-full flex justify-center items-center overflow-hidden"
        >
          <iframe
            ref={iframeRef}
            className="w-[1440px] h-[750px] border-none"
            style={{
              margin: '0',
              padding: '0',
              display: 'block'
            }}
            title="Print Frame"
          >
            {iframeDocument && ReactDOM.createPortal(children, iframeDocument.body)}
          </iframe>
        </div>
      </div>
    </div>

  )
}

export default IframeComponent

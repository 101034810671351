import React from 'react'

export const ASquareImg = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="249" height="248" viewBox="0 0 249 248" fill="none">
  <path d="M1.83203 246.416L63.2329 124.188M247.436 246.416L186.035 124.188M63.2329 124.188L124.634 1.95996L186.035 124.188M63.2329 124.188H186.035" stroke="black" strokeWidth="3" strokeLinejoin="round"/>
</svg>

  )
}

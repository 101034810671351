import React from 'react'
import styles from './introTwo.module.css'
import { HourglassBigImg } from '../img/HourglassBigImg'
import { TwoCirclesImg } from '../img/TwoCirclesImg'
import { TwoLoopsImg } from '../img/TwoLoopsImg'
import { StarImg } from '../img/StarImg'
import { CircleLineImg } from '../img/CircleLineImg'
import { UpsideDownVImg } from '../img/UpsideDownVImg'
import { HavasGroupImg } from '../img/HavasGroupImg'

export const IntroTwoComponent = ({ projectData }) => {
  if (!projectData) {
    return <div>Loading...</div>
  }

  return (
    <div className={styles['HAV-web']}>
      <div className={styles.overlap}>
        <div className={styles.frame}>
          <div className={styles.div}>
            <div className={styles.group}>
              <div className={styles['overlap-group']}>
                <div className={styles.square}>
                  <StarImg className={styles.vector} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <div className={styles['group-wrapper']}>
              <div className={styles['overlap-group-wrapper']}>
                <div className={styles['overlap-group']}>
                  <div className={styles.square}>
                    <CircleLineImg className={styles.rectangle} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <div className={styles.square}>
              <TwoCirclesImg className={styles.element} />
            </div>
            <p className={styles['text-wrapper']}>
              Web accessibility ensures that the internet is usable by everyone, including people with disabilities.
            </p>
          </div>
          <div className={styles.div}>
            <div className={styles['div-wrapper']}>
              <div className={styles['rectangle-wrapper']}>
                <div className={styles.square}>
                  <TwoLoopsImg className={styles['rectangle-2']} />
                </div>
              </div>
            </div>
            <p className={styles['text-wrapper']}>
              It&apos;s about creating websites and applications that are flexible to meet different user needs,
              preferences, and situations.
            </p>
          </div>
          <div className={styles.div}>
            <div className={styles.square}>
              <HourglassBigImg className={styles['element-2']} width={'288'} height={'288'} />
            </div>
            <p className={styles['text-wrapper']}>
              This inclusivity is not only a matter of social responsibility but also a legal requirement under
              initiatives like the European Accessibility Act (EAA).
            </p>
          </div>
          <div className={styles.div}>
            <div className={styles['group-2']}>
              <div className={styles['overlap-group-2']}>
                <div className={styles.square}>
                  <UpsideDownVImg className={styles['vector-2']} alt="Vector" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <img className={styles['element-3']} alt="Element" />
            <p className={styles['text-wrapper']}>
              This inclusivity is not only a matter of social responsibility but also a legal requirement under
              initiatives like the European Accessibility Act (EAA).
            </p>
          </div>
        </div>
        <div className={styles['frame-2']}>
          <div className={styles['frame-3']}>
            <div className={styles['frame-4']}>
              <HavasGroupImg className={styles['group-3']} alt="Group" />
              <div className={styles['text-wrapper-2']}>Global Client Technology</div>
            </div>
            <div className={styles['text-wrapper-3']}>Understanding Web Accessibility</div>
          </div>
          <div className={styles['frame-5']}>
            <div className={styles['frame-6']}>
              <div className={styles['frame-4']}>
                <div className={styles['text-wrapper-4']}>Project</div>
                <div className={styles['text-wrapper-5']}>{projectData.name}</div>
              </div>
              <div className={styles['frame-4']}>
                <div className={styles['text-wrapper-4']}>Scope</div>
                <div className={styles['text-wrapper-5']}>{projectData.scope}</div>
              </div>
            </div>
            <div className={styles['web-accessibility']}>
              Web <br />
              accessibility <br />
              analysis
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

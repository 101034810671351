import React from 'react'
export const ThreeSquaresImg = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
  <path d="M1.65063 1.05579H32.6506H63.6506V32.0558V63.0558H32.6506H1.65063V1.05579Z" fill="white"/>
  <path d="M32.6506 1.05579H1.65063V63.0558H32.6506M32.6506 1.05579H63.6506V32.0558M32.6506 1.05579V32.0558M32.6506 63.0558H63.6506V32.0558M32.6506 63.0558V32.0558M63.6506 32.0558H32.6506" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M1.65063 1.05579V63.0558H32.6506V32.0558V1.05579H1.65063Z" fill="#D32D1F" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
  <path d="M63.6506 1.05579H32.6506V32.0558H63.6506V1.05579Z" fill="#F9F9F9" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
</svg>
  )
}

import React, { useState, useEffect } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/20/solid'
import { getDaysInMonth, daysInWeek } from '../../utils/dateUtils'
import { classNames } from '../../utils/classNames'

export default function Calendar () {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [days, setDays] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)

  useEffect(() => {
    setDays(getDaysInMonth(currentDate))
  }, [currentDate])

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1))
  }

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1))
  }

  return (
    <div className="mt-1 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-1 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          onClick={prevMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="size-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-xs font-semibold">
          {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </div>
        <button
          type="button"
          onClick={nextMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="size-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs/7 text-gray-500">
        {daysInWeek.map((day, idx) => (
          <div key={idx}>{day}</div>
        ))}
      </div>
      <div className="mt-1 grid grid-cols-7 gap-px rounded-lg text-xs">
        {days?.map((day) => (
          <button
            key={day.date}
            type="button"
            onClick={() => setSelectedDate(day.date)}
            className={classNames(
              'mx-auto flex size-7 items-center justify-center rounded-full hover:bg-gray-100 focus:z-10',
              !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
              !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
              day.isToday && !day.isSelected && 'text-indigo-600',
              day.isSelected && day.isToday && 'bg-indigo-600',
              day.isSelected && !day.isToday && 'bg-gray-900',
              day === selectedDate && 'bg-gray-100 text-black font-semibold'
            )}
          >
            <time
              dateTime={day.date}
              className={classNames(
                'mx-auto flex size-7 items-center justify-center rounded-full',
                day.isSelected && day.isToday && 'bg-indigo-600',
                day.isSelected && !day.isToday && 'bg-gray-900'
              )}
            >
              {day.date.split('-').pop().replace(/^0/, '')}
            </time>
          </button>
        ))}
      </div>
    </div>
  )
}

import React from 'react'

export const PineTreeImg = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="216" height="218" viewBox="0 0 216 218" fill="none">
<path d="M108.16 1.57336L178.768 146.951H108.16H37.5508L108.16 1.57336Z" fill="#D32D1F"/>
<path d="M108.16 146.951H178.768L108.16 1.57336L37.5508 146.951H108.16ZM108.16 146.951V217.337" stroke="black" strokeWidth="3" strokeLinejoin="round"/>
</svg>
  )
}
